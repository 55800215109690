import React, { useState } from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { updateProfile } from 'src/api/api.ts';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { updateUserProfile } from 'src/redux/slices/userSlice';

interface InputProps {
  profile: API.ProfileMe;
}

const EditProfile: React.FC<InputProps> = ({ profile }: InputProps) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: API.ProfileMe) => {
    setConfirmLoading(true);
    try {
      const { id, ...profileWithoutId } = profile;
      let payload: API.Profile = {
        ...profileWithoutId,
        ...values,
      };
      const response = await updateProfile(accessToken, profile.id, payload);
      dispatch(updateUserProfile(response.data));
      notification['success']({
        message: t('Success'),
        description: t('My Profile information has been updated successfully!'),
        placement: 'topRight',
        duration: 4.5,
      });
      setOpen(false);
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 422) {
        error?.response?.data?.first_name?.map((msg: string) => form.setFields([{ name: 'first_name', errors: [`${msg}`] }]));
        error?.response?.data?.last_name?.map((msg: string) => form.setFields([{ name: 'last_name', errors: [`${msg}`] }]));
        error?.response?.data?.phone?.map((msg: string) => form.setFields([{ name: 'phone', errors: [`${msg}`] }]));
        error?.response?.data?.address?.map((msg: string) => form.setFields([{ name: 'address', errors: [`${msg}`] }]));
        error?.response?.data?.zip_code?.map((msg: string) => form.setFields([{ name: 'zip_code', errors: [`${msg}`] }]));
        error?.response?.data?.city?.map((msg: string) => form.setFields([{ name: 'city', errors: [`${msg}`] }]));
        error?.response?.data?.bank_account?.map((msg: string) => form.setFields([{ name: 'bank_account', errors: [`${msg}`] }]));
      }
    };
    setConfirmLoading(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        {t('Edit')}
      </Button>
      <Modal
        title={t('Edit Profile')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            address: profile.address,
            first_name: profile.first_name,
            last_name: profile.last_name,
            phone: profile.phone,
            zip_code: profile.zip_code,
            bank_account: profile.bank_account,
            city: profile.city,
          }}
        >
          <Form.Item
            label={t('First Name')}
            name="first_name"
          >
            <Input
              size="middle"
              disabled
            />
          </Form.Item>
          <Form.Item
            label={t('Last Name')}
            name="last_name"
          >
            <Input
              size="middle"
              disabled
            />
          </Form.Item>
          <Form.Item
            label={t('Telephone')}
            name="phone"
            rules={[
              {
                pattern: new RegExp(/^\+?\d{1,3}?\s?\d{1,4}\s?\d{1,4}\s?\d{1,4}$/),
                message: t('Please enter valid telephone no'),
              },
            ]}
          >
            <Input
              maxLength={15}
              size="middle"
              placeholder={t('Enter your telephone no. here')}
            />
          </Form.Item>
          <Form.Item
            label={t('Address')}
            name="address"
          >
            <Input
              size="middle"
              maxLength={255}
              placeholder={t('Enter your address here')}
            />
          </Form.Item>
          <Form.Item
            label={t('Zip Code')}
            name="zip_code"
            rules={[
              {
                pattern: new RegExp(/^\d{4}$/),
                message: t('Zip code should have only 4 digits'),
              },
            ]}
          >
            <Input
              size="middle"
              showCount
              maxLength={4}
              placeholder={t('Enter your zip code here')}
            />
          </Form.Item>
          <Form.Item
            label={t('City')}
            name="city"
            rules={[
              {
                pattern: new RegExp(/^[0-9a-zA-ZøæåØÆÅ#,._ -]+$/i),
                message: t('Special characters are not allowed'),
              },
            ]}
          >
            <Input
              size="middle"
              maxLength={50}
              placeholder={t('Enter your city here')}
            />
          </Form.Item>
          <Form.Item
            label={t('Bank Account')}
            name="bank_account"
            rules={[
              {
                pattern: new RegExp(/^[0-9a-zA-Z ]+$/i),
                message: t('Special characters are not allowed'),
              },
            ]}
          >
            <Input
              size="middle"
              maxLength={15}
              placeholder={t('Enter your bank account here')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditProfile;