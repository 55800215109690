import api from "./axiosInstance";

const URL = {
  base: process.env.REACT_APP_BASE_URL,
  login: process.env.REACT_APP_LOGIN_URL,
  resetPasswordUrl: process.env.REACT_APP_RESET_PASSWORD_URL,
  resendVerify: process.env.REACT_APP_RESEND_VERIFY,
  client: process.env.REACT_APP_CLIENT,
  expenseHeader: process.env.REACT_APP_EXPENSE_HEADER,
  expenseAccount: process.env.REACT_APP_EXPENSE_ACCOUNT,
  dimension: process.env.REACT_APP_DIMENSION,
  project: process.env.REACT_APP_PROJECT,
  companyCard: process.env.REACT_APP_COMPANY_CARD,
  authUserList: process.env.REACT_APP_AUTH_USER_URL,
  authRefreshToken: process.env.REACT_APP_AUTH_REFRESH_TOKEN_URL,
  entitlement: process.env.REACT_APP_ENTITLEMENT_URL,
  profile: process.env.REACT_APP_PROFILE,
  vat: process.env.REACT_APP_VAT
};
const APIKEY = {
  identityServerAux: process.env.REACT_APP_IDENTITY_SERVER_AUXILIARY_API_KEY,
}

/**
 * Login APi
 */
export async function userLogin(payload: API.loginPayload) {
  const test = api({
    method: 'post',
    url: URL.login,
    data: payload
  });

  return test;
}

/**
 * Retrieve clients.
 */
export async function getClients(token: string, params: API.FetchParams) {
  return api({
    method: 'get',
    url: URL.client,
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

/**
 * Retrieve clients.
 */
export async function getClient(token: string, clientId: string) {
  return api({
    method: 'get',
    url: `${URL.client}${clientId}/`,
    headers: { Authorization: `Bearer ${token}` },
  });
}

/**
 * Retrieve list of expenses.
 */
export async function getExpensesList(token: string, params: API.FetchParams) {
  const url = URL.expenseHeader + 'me/';
  return api({
    method: 'get',
    url: url,
    headers: { Authorization: `Bearer ${token}` },
    params: params
  });
}

/**
 * Retrieve expense header.
 */
export async function getExpenseHeader(token: string, headerId: string) {
  const url = URL.expenseHeader + headerId + '/';
  return api({
    method: 'get',
    url: url,
    headers: { Authorization: `Bearer ${token}` }
  });
}

/**
 * Create expense header.
 */
export async function createExpenseHeader(token: string, data: API.expenseHeaderParams) {
  return api({
    method: 'post',
    url: URL.expenseHeader,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  })
}

/**
 * Update expense header.
 */
export async function updateExpenseHeader(token: string, headerId: string, data: API.expenseHeaderParams) {
  const url = URL.expenseHeader + headerId + '/';
  return api({
    method: 'put',
    url: url,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  });
}

export async function updateExpenseRows(token: string, headerId: string, data: API.expenseRow[] ) {
  const url = URL.expenseHeader + headerId + '/rows/';
  return api({
    method: 'post',
    url: url,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  });
}

/**
 * Approve and send expense header.
 */
export async function sendExpenseHeader(token: string, headerId: string) {
  const url = URL.expenseHeader + headerId + '/send/';
  return api({
    method: 'post',
    url: url,
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * delete expense header.
 */
export async function deleteExpenseHeader(token: string, headerId: string) {
  const url = URL.expenseHeader + headerId + '/';
  return api({
    method: 'delete',
    url: url,
    headers: { Authorization: `Bearer ${token}` }
  });
}

/**
 * @description API call to get a collection of cost types (also known as expense accounts)
 */
export async function getCostTypes(token: string, params: API.FetchParams) {
  return api({
    method: 'get',
    url: URL.expenseAccount,
    headers: { Authorization: `Bearer ${token}` },
    params: params
  });
}

/**
 * @description API call to get a single cost type (also known as expense account)
 */
export async function getCostType(token: string, costTypeId: string) {
  return api({
    method: 'get',
    url: `${URL.expenseAccount}${costTypeId}/`,
    headers: { Authorization: `Bearer ${token}` },
  });
}

/**
 * @description API call to add a cost type (also known as expense account)
 */
export async function addCostType(token: string, data: API.CostType) {
  return api({
    method: 'POST',
    url: URL.expenseAccount,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  });
}

/**
 * @description API call to update a cost type (also known as expense account)
 */
export async function updateCostType(token: string, costTypeId: string, data: API.CostType) {
  return api({
    method: 'put',
    url: `${URL.expenseAccount}${costTypeId}/`,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  });
}

/**
 * Get a list of dimensions
 */
export async function getDimensionCollection(token: string, params: API.FetchParams) {
  return api({
    method: 'get',
    url: URL.dimension,
    headers: { Authorization: `Bearer ${token}` },
    params: params
  })
}

/**
 * Get a single dimension by id
 */
export async function getDimension(token: string, dimesnionId: string) {
  const url = URL.dimension + dimesnionId + '/';
  return api({
    method: 'get',
    url: url,
    headers: { Authorization: `Bearer ${token}` }
  })
}

/**
 * Add a dimension
 */
export async function addDimension(token: string, data: API.Dimension) {
  const url = URL.dimension;
  return api({
    method: 'post',
    url: url,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  })
}

/**
 * Update a dimension
 */
export async function updateDimension(token: string, data: API.Dimension) {
  const url = URL.dimension + data.id! + '/';
  return api({
    method: 'put',
    url: url,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  })
}

/**
 * Upload a file relative to an expense row
 */
export async function uploadExpenseAttachment(token: string, headerId: string, rowId: string, file: any) {
  const url = URL.expenseHeader + headerId + '/row/' + rowId + '/attachment/';
  const formData = new FormData();
  formData.append('attachment', file);
  return api({
    method: 'post',
    url: url,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',  // This header is necessary for file uploads
    },
    data: formData,
  })
}

/**
 * Download a file relative to an expense row
 */
export async function downloadExpenseAttachment(token: string, headerId: string, rowId: string){
  const url = URL.expenseHeader + headerId + '/row/' + rowId + '/attachment/';
  const response = await api({
    method: 'get',
    url: url,
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  });
  return response;
}

/**
 * Get a list of projects
 */
export async function getProjectCollection(token: string, params:API.FetchParams) {
  return api({
    method: 'get',
    url: URL.project,
    headers: { Authorization: `Bearer ${token}` },
    params: params
  })
}

/**
 * Get a single project by id
 */
export async function getProject(token: string, projectId: string) {
  const url = URL.project + projectId + '/';
  return api({
    method: 'get',
    url: url,
    headers: { Authorization: `Bearer ${token}` }
  })
}

/**
 * Add a project
 */
export async function addProject(token: string, data: API.Project) {
  const url = URL.project;
  return api({
    method: 'post',
    url: url,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  })
}

/**
 * Update a project
 */
export async function updateProject(token: string, data: API.Project) {
  const url = URL.project + data.id! + '/';
  return api({
    method: 'put',
    url: url,
    headers: { Authorization: `Bearer ${token}` },
    data: data
  })
}

export async function getCompanyCardCollection(token: string, params: API.FetchParams) {
  return api({
    method: 'get',
    url: URL.companyCard,
    headers: { Authorization: `Bearer ${token}` },
    params: params
  })
}

export async function getCompanyCard(token: string, cardId: string) {
  return api({
    method: 'get',
    url: `${URL.companyCard}${cardId}/`,
    headers: { Authorization: `Bearer ${token}` },
  })
}

export async function addCompanyCard(token: string, payload: API.CompanyCardPayload) {
  return api({
    method: 'post',
    url: URL.companyCard,
    headers: { Authorization: `Bearer ${token}` },
    data: payload,
  })
}

export async function updateCompanyCard(token: string, payload: API.CompanyCardPayload, cardId: string) {
  return api({
    method: 'put',
    url: `${URL.companyCard}${cardId}/`,
    headers: { Authorization: `Bearer ${token}` },
    data: payload,
  })
}

export async function resetPassword(email: string) {
  return api({
    method: 'post',
    url: URL.resetPasswordUrl,
    data: { email: email }
  })
}

/**
 * Get all users in current tenant.
 */
export async function getUsers(token: string) {
  return api({
    method: 'get',
    url: URL.authUserList,
    headers: { Authorization: `Bearer ${token}` },
  })
}
/**
 * Add user to current tenant.
 */
export async function addUser(token: string, payload: API.AddUserPayload) {
  return api({
    method: 'post',
    url: URL.authUserList,
    headers: { Authorization: `Bearer ${token}` },
    data: payload,
  })
}
/*
 * update information of given user
 */
export async function updateUser(
  token: string,
  email: string,
  payload: API.UpdateUserPayload
) {
  return api({
    method: 'put',
    url: `${URL.authUserList}${email}/`,
    headers: { Authorization: `Bearer ${token}` },
    data: payload,
  })
}

/**
 * Refresh token
 */
export async function refreshToken(refresh: string) {
  return api({
    method: 'post',
    url: URL.authRefreshToken,
    headers: { 'Content-Type': 'application/json' },
    data: { refresh: refresh },
  })
}
/**
 * Loading all entitlement groups.
 */
export async function getEntitlementGroups(token: string) {
  return api({
    method: 'get',
    url: `${URL.entitlement}groups/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
/**
 * Remove entitlement group by id
 * @param groupId
 * @param token
 */
export async function removeGroup(groupId: string, token: string) {
  return api({
    method: 'delete',
    url: `${URL.entitlement}groups/${groupId}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
/**
 * create new entitlements groups
 * @param payload : with group name and required info
 * @param token: access token 
 * @returns 
 */
export async function createEntitlementGroup( payload: API.CreateGroupPayload, token: string) {
  return api({
    method: 'post',
    url: `${URL.entitlement}groups/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload
  })
}

export async function getMembers(groupId: string, token: string) {
  return api({
    method: 'get',
    url: `${URL.entitlement}groups/${groupId}/members/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export async function removeGroupMember(
  groupId: string,
  memberId: string,
  token: string
) {
  return api({
    method: 'delete',
    url: `${URL.entitlement}groups/${groupId}/members/${memberId}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Add member to given entitlement group.
 */
export async function addMember(
  groupId: string,
  payload: API.AddMemberPayload,
  token: string
) {
  return api({
    method: 'post',
    url: `${URL.entitlement}groups/${groupId}/members/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  })
}
/**
 * Get my information: get my profile
 */
export async function getMyProfile(
  token: string
) {
  return api({
    method: 'get',
    url: `${URL.profile}me/`,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
}

/**
 * @description API to get profile by userId
 */
export async function getProfileByUserId(
  token: string,
  userId: string,
) {
  return api({
    method: 'get',
    url: `${URL.profile}user_id/${userId}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
}

export async function updateProfile(
  token: string,
  profileId: string,
  payload: API.Profile
) {
  return api({
    method: 'put',
    url: `${URL.profile}${profileId}/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  })
}

export async function addProfile(
  token: string,
  payload: API.Profile
) {
  return api({
    method: 'POST',
    url: `${URL.profile}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  })
}

/**
 * @description API call to get VAT codes
 */
export async function getVatCodes(
  token: string,
  targetSystem: string
) {
  return api({
    method: 'get',
    url: URL.vat,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      schema: targetSystem,
    }
  })
}

/**
 * @description API call to get VAT codes
 */
export async function resendVerificationEmail(
  token: string,
  email: string,
) {
  return api({
    method: 'post',
    url: URL.resendVerify,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-api-key': APIKEY.identityServerAux, 
    },
    params: {
      email: email,
    }
  })
}