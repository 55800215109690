import React, { useState } from 'react';
import { List, Row, Col, Space, Flex, Tooltip } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import EditProfile from './edit';
import { themeSettings } from 'src/configs/index.tsx';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { setUserProfile } from 'src/redux/slices/userSlice';

const MyInformation: React.FC = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const userProfile = useAppSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);

  const transformData = () => {
    return [
      {
        title: t('First Name'),
        value: userProfile.first_name
      },
      {
        title: t('Last Name'),
        value: userProfile.last_name
      },
      {
        title: t('Email'),
        value: userProfile.user_id
      },
      {
        title: t('Telephone'),
        value: userProfile.phone
      },
      {
        title: t('Address'),
        value: userProfile.address
      },
      {
        title: t('Zip Code'),
        value: userProfile.zip_code
      },
      {
        title: t('City'),
        value: userProfile.city
      },
      {
        title: t('Social sec'),
        value: userProfile.social_sec
      },
      {
        title: t('Bank Account'),
        value: userProfile.bank_account
      },
    ];
  }

  const fetchMyProfileInfo = async () => {
    setLoading(true);
    await dispatch(setUserProfile(accessToken));
    setLoading(false);
  }

  return (
    <>
      <Row>
        <Col xs={24} sm={18} md={15} lg={12} xl={12} xxl={12}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Flex justify='space-between'>
              <EditProfile
                profile={userProfile}
              />
              <Tooltip title={t('Refresh')} color={themeSettings.token.colorPrimary} key="refresh-company-cards" >
                <RedoOutlined onClick={() => fetchMyProfileInfo()} />
              </Tooltip>
            </Flex>
            <List
              loading={loading}
              bordered
              className='expense-my-information'
              dataSource={transformData()}
              renderItem={(item) => (
                <List.Item key={item.title}>
                  <List.Item.Meta
                    title={item.title}
                  />
                  <div>{item.value}</div>
                </List.Item>
              )}
            />
          </Space>
        </Col>
      </Row>
    </>
  );
}
export default MyInformation;